ul {
    list-style-type: none;
}
ul li {
    float: left;
    white-space: nowrap;
    text-align: center;
    display: block;
    width: 10%;
    padding: 0.5em 0;
    text-decoration: none;
    background-color: white;
    color: black;
    border-right: 0px solid white;
}
